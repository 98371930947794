import { FunctionComponent } from "react"
import { Comment } from "../../types"
import CommentBranch from "./branch"
import React from "react"

type Props = {
  comments: Comment[]
  onLike: (commentId: string, amount: number) => void
  onDislike: (commentId: string, amount: number) => void
  onReply: (commentId: string | null) => void
  onSubmit: (text: string) => void
  active: string | null
  loading?: string
}

const CommentTree: FunctionComponent<Props> = ({
  comments,
  onLike,
  onDislike,
  onReply,
  onSubmit,
  active,
  loading,
  children,
}) => (
  <>
    {(comments.length || children) && (
      <ul>
        {children && <li>{children}</li>}

        {comments.map(comment => (
          <li key={comment.id}>
            <CommentBranch
              comment={comment}
              onLike={onLike}
              onDislike={onDislike}
              onReply={onReply}
              onSubmit={onSubmit}
              selected={active}
              loading={loading}
            />
          </li>
        ))}
      </ul>
    )}

    <style jsx>{`
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      li:not(:first-child) {
        margin-top: 1em;
      }
      li:not(:last-child) {
        margin-bottom: 1em;
      }
    `}</style>
  </>
)

export default CommentTree
