import { FunctionComponent } from "react"
import { IconLookup } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export type Props = {
  label: string
  icon: IconLookup
}

const DropdownItem: FunctionComponent<Props> = ({ icon, label }) => (
  <div>
    <FontAwesomeIcon icon={icon} fixedWidth />

    <span>{label}</span>

    <style jsx>{`
      div {
        display: flex;
        /*align-items: center;*/
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
      span {
        /*margin: 0 0.5em;*/
        margin-left: 15px;
        margin-right: 15px;
      }
    `}</style>
  </div>
)

export default DropdownItem
