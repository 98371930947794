import { FunctionComponent, ButtonHTMLAttributes, useReducer } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons"
import DropdownList, { Props as DropdownListProps } from "../dropdown/list"
import { useMenus } from "../../../hooks/use-menus"
import { useStyle } from "../../../hooks/use-style"
import { lightTheme, darkTheme } from "../../../services/theme"
import React from "react"

export type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  items: DropdownListProps["items"]
}

const OptionsButton: FunctionComponent<Props> = ({ items, ...props }) => {
  const [menu, deactivate] = useMenus(["options"])

  const css = useStyle(theme => ({
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  return (
    <>
      <DropdownList
        onClickOutside={deactivate}
        visible={menu.options.isActive}
        placement="bottom-end"
        element={({ ref }) => (
          <button
            ref={ref}
            {...props}
            onClick={menu.options.setActive}
            className={
              (props.className ? props.className + " " : "") +
              (menu.options.isActive ? "active" : "")
            }
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        )}
        items={items}
      />
      <style jsx>{`
        button {
          color: inherit;
          background-color: transparent;
          transition: 200ms background-color;
          border-radius: 0.3em;
          padding-top: 0.2em;
          padding-bottom: 0.1em;
          border: none;
        }
        button:hover,
        .active {
          background-color: ${css.activeButtonBackgroundColor};
          cursor: pointer;
        }
      `}</style>
    </>
  )
}

export default OptionsButton
