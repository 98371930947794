import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import ButterToast from "butter-toast"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import CommentSection from "../components/comment/section"
import ItemPreview from "../components/item/preview"
import ThreeColumnLayout from "../components/layout/three-column-layout"
import Widget from "../components/layout/widget"
import Notification from "../components/notification"
import { useFilters } from "../hooks/use-filters"
import { useItem } from "../hooks/use-item"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { convertComments, convertContentItems } from "../services/content"
import { GET_comments, GET_item } from "../services/http"
import { rpc } from "../services/node"
import { Comment, Item, LikeType } from "../types"

var demoCommentPublishMessages = [
  "Keep on writing!\nWhen people smartlike your posts you receive money.\n",
  "Amazing! Good post!\n",
  "Keep on posting clever comments and get rewarded!",
]

var demoCommentUpvoteMessages = [
  "Thank you!\nYou reward the author and help others discover the comment.\n",
  "Amazing! Good comment wins!",
  "Amazing! Good comment gets promoted!",
  "Great! Clever commentators are rewarded!",
]

var demoCommentDownvoteMessages = [
  "Let the good comments win!",
  "Thanks for helping curating discussion!",
]

type Props = {
  item: Item | null
  comments: Comment[]
}

const ItemPage: FunctionComponent = () => {
  /*const ItemPage: NextPage<Props> = ({
    item: initialItem,
    comments: initialComments
}) => {*/

  //if (!initialItem) {
  //    return <Error statusCode={404} />
  //}

  //const parts = location.pathname.split("/")
  //const itemId = parts[2]
  //console.log("item page " + parts[2])

  const location = useLocation()
  const { item, setItem, likeItem } = useItem(undefined)
  const { user, updateCommentLikes, isLoggedIn } = useUser()
  const [comments, setComments] = useState([])
  const { filters } = useFilters()

  let itemUrl1 = ""

  useEffect(() => {
    const parts = location.pathname.split("/")
    const itemId = parts[2]

    console.log("loading item " + itemId)
    GET_item(itemId).then(response => {
      if (response?.hits?.hits.length) {
        let items = convertContentItems(response, filters)
        if (items.length) {
          setItem(items[0])
          console.log("setting item url " + items[0].url)
          itemUrl1 = items[0].url
        }
      }
    })

    GET_comments(itemId).then(response => {
      if (response?.hits?.hits.length) {
        console.log(response)
        //let items = convertContentItems(response)
        //if (items.length) setItem(items[0])
        setComments(convertComments(response, filters))
      }
    })
  }, [filters])

  const comment = useCallback(
    async (amount: number, text: string) => {
      console.log("comment " + text)
      sendCommentAction("publish", "", amount, text, demoCommentPublishMessages)
    },
    [user?.profile.name, item]
  )

  const upvoteComment = useCallback(
    async (commentId: string, amount: number) => {
      sendCommentAction(
        "upvote",
        commentId,
        amount,
        "",
        demoCommentUpvoteMessages
      )
    },
    [user?.profile.name, item]
  )

  const downvoteComment = useCallback(
    async (commentId: string, amount: number) => {
      sendCommentAction(
        "downvote",
        commentId,
        amount,
        "",
        demoCommentDownvoteMessages
      )
    },
    [user?.profile.name, item]
  )

  const reply = useCallback(
    async (commentId: string, amount: number, text: string) => {
      sendCommentAction(
        "publish",
        commentId,
        amount,
        text,
        demoCommentPublishMessages
      )
    },
    [user?.id, item]
  )

  const sendCommentAction = useCallback(
    (
      action: string,
      parentId: string,
      amount: number,
      text: string,
      messages: string[]
    ) => {
      if (user && isLoggedIn()) {
        console.log("sending comment " + item.url)
        const message = messages[Math.floor(Math.random() * messages.length)]
        import(
          "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
        ).then(image => {
          const c = React.createElement(
            Notification,
            {
              image: image.default,
              creator: "",
              text: message,
            },
            null
          )

          ButterToast.raise({
            content: c,
            timeout: 3000,
          })
        })

        const msg = {
          kind: LikeType.Comment,
          target: item.url,
          amount: amount,
          currency: "USD",
          payload: JSON.stringify({
            action: action,
            text: text,
            parent_id: parentId,
          }),
          /*id: Math.floor(Math.random() * 100000000),*/
        }
        rpc("like", JSON.stringify(msg), user, getCachedSecret(), function (
          res
        ) {
          if (res.status == "ok") {
            console.log("Commenting successful", res)
          } else console.error("Commenting failed")
        })
      } else {
        navigate("/login", { state: { modal: true } })
      }
    },
    [item, user]
  )

  return (
    <>
      {item && (
        <Head>
          <title>{item.title} | Smartlike</title>
          <meta property="og:title" content={`${item.title} | Smartlike`} />
          <meta name="description" content={item.description} />
          <meta property="og:description" content={item.description} />
          {item.image && <meta property="og:image" content={item.image} />}
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/item/${item.id}`}
          />
        </Head>
      )}

      <ThreeColumnLayout>
        {{
          content: (
            <>
              {/*<ItemView item={item} onLike={likeItem} onUpdate={setItem} />*/}
              {item && (
                <>
                  <ItemPreview
                    liked={false}
                    item={item}
                    onLike={likeItem}
                    onUpdate={setItem}
                  />
                  <Widget title="Comments" numComments={item.comments_count}>
                    <CommentSection
                      comments={comments}
                      onLike={upvoteComment}
                      onDislike={downvoteComment}
                      onComment={comment}
                      onReply={reply}
                    />
                  </Widget>
                </>
              )}
            </>
          ),
          sidebar: null,
        }}
      </ThreeColumnLayout>
    </>
  )
}
export default ItemPage
