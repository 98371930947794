import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { faFlag, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import {
  faReply,
  faThumbsUp,
  faArrowDown,
  faArrowUp,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons"
import { faThumbsUp as faThumbsUpActive } from "@fortawesome/pro-solid-svg-icons"
import { Comment, LikeType } from "../../types"
import CommentTree from "./tree"
import CommentInput from "./input"
import { lightTheme, darkTheme } from "../../services/theme"
import { prettyDate } from "../../services/date"
import { useStyle } from "../../hooks/use-style"
import OptionsButton from "../layout/button/options"
import Description from "../layout/description"
import ActionButton from "../layout/button/action"
import Panel from "../layout/panel"
import CommentLabel from "../comment/label"
import { useUser } from "../../hooks/use-user"
import { useAnimatedCallback } from "../../hooks/use-animated-callback"
import React from "react"
import { GET_comments } from "../../services/http"
import { convertComments } from "../../services/content"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useComments } from "../../hooks/use-comments"
import { useFilters } from "../../hooks/use-filters"

type Props = {
  comment: Comment
  onLike: (commentId: string, amount: number) => void
  onDislike: (commentId: string, amount: number) => void
  onReply: (commentId: string | null) => void
  onSubmit: (amount: number, text: string) => void
  selected: string | null
  standalone?: boolean
  loading?: string
}

const CommentBranch: FunctionComponent<Props> = ({
  comment,
  onLike,
  onDislike,
  onReply,
  onSubmit,
  selected,
  standalone: isStandalone = false,
  loading,
}) => {
  const {
    comments,
    setComments,
    upvoteComment,
    downvoteComment,
    publishComment,
  } = useComments(
    {
      kind: LikeType.CommentChannel,
      id: comment.id,
      url: comment.author.title,
      count: comment.comments_count,
    },
    comment.comments,
    false
  )

  const { filters } = useFilters()
  const { user, commentLikes } = useUser()
  const isLiked = useMemo(() => commentLikes.includes(comment.id), [
    commentLikes,
    comment.id,
  ])

  const reply = useCallback(() => onReply(comment.dht_key), [
    comment.dht_key,
    onReply,
  ])
  const cancel = useCallback(() => onReply(null), [onReply])

  const likeButton = useRef<HTMLButtonElement>(null)
  const likeComment = useAnimatedCallback(
    likeButton,
    "heartBeat",
    () => onLike(comment.dht_key, 1),
    [comment.dht_key, onLike]
  )

  const loadReplies = useCallback(
    async (parent: string) => {
      if (comments.length) {
        setComments([])
      } else {
        GET_comments(parent).then(response => {
          if (response?.hits?.hits.length) {
            console.log(response)
            setComments(convertComments(response, filters))
          }
        })
      }
    },
    [comments, filters]
  )

  const css = useStyle(theme => ({
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    borderColor: isStandalone
      ? "transparent"
      : theme === "light"
      ? lightTheme.color.border
      : darkTheme.color.border,
  }))

  return (
    <article>
      <div id={comment.id} className="hook"></div>

      <div className="main">
        <div className="layout">
          <Panel url="#">
            {{
              header: (
                <>
                  <div className="table">
                    <header className="table-cell">
                      <CommentLabel channel={comment.author} />
                    </header>
                    <div className="table-cell">
                      <div className="published">
                        {prettyDate(comment.published_at, true)}
                      </div>
                    </div>
                  </div>
                </>
              ),
              content: (
                <div className="description">
                  <Description linkify>{comment.text}</Description>
                </div>
              ),
              footer: (
                <>
                  {comment.comments_count > 0 && (
                    <p
                      className="replies"
                      onClick={() => loadReplies(comment.id)}
                    >
                      {comment.comments_count}
                      &nbsp;
                      {comment.comments_count === 1 ? "reply" : "replies"}
                    </p>
                  )}

                  {/*comment.amount > 0 && (
                    <p>${(comment.amount / 100).toFixed(2)}</p>
                  )*/}
                </>
              ),
              upperToolbar: (
                <OptionsButton
                  items={{
                    report: {
                      label: "Report",
                      icon: faFlag,
                    },
                  }}
                />
              ),
              lowerToolbar: (
                <>
                  <div className="vote" onClick={reply}>
                    <FontAwesomeIcon icon={faReply} />
                  </div>
                  <div className="table">
                    <div className="table-row">
                      <div
                        className="vote"
                        onClick={() => onDislike(comment.id, 1)}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                      <div
                        style={{ visibility: "visible" }}
                        className={
                          comment.score >= 0
                            ? "score-positive"
                            : "score-negative"
                        }
                      >
                        {comment.score}
                      </div>
                      <div
                        className="vote"
                        onClick={() => onLike(comment.id, 1)}
                      >
                        <FontAwesomeIcon icon={faChevronUp} />
                      </div>
                    </div>
                  </div>
                </>
              ),
            }}
          </Panel>
        </div>

        <div className="comments">
          <CommentTree
            comments={comments}
            onLike={upvoteComment}
            onDislike={downvoteComment}
            onComment={publishComment}
            onReply={reply}
            onSubmit={publishComment}
            active={selected}
          >
            {comment.dht_key === selected && (
              <CommentInput
                autofocus={!!selected && comment.dht_key === selected}
                onSubmit={onSubmit}
                onCancel={cancel}
                placeholder={`Reply to ${
                  comment.author.title[comment.author.title.length - 1] !== "s"
                    ? comment.author.title + "'s"
                    : comment.author.title + "'"
                } comment`}
              />
            )}
          </CommentTree>
        </div>
      </div>

      <style jsx>{`
        article {
          display: flex;
          border-radius: 0.3em;
          position: relative;
          align-items: flex-start;
        }
        .hook {
          position: absolute;
          top: -80px;
        }
        .main {
          flex: 1;
        }
        .layout {
          display: flex;
          overflow-wrap: break-word;
          justify-content: space-between;
          min-width: 0;
          border-top: 1px solid ${css.borderColor};
          padding-top: 1em;
          transition: 200ms border-color;
        }
        h1 {
          font-size: 0.9em;
          margin: 0.15em 0 0.15em;
        }
        .description {
          font-size: 0.8em;
          margin-top: 5px;
        }
        p {
          display: flex;
          font-size: 0.8em;
          color: ${css.infoColor};
          transition: 200ms color;
          margin: 0;
        }
        p:not(:first-child)::before {
          /*content: "•";
          margin-left: -0.5em;*/
          margin-left: 1em;
          padding-right: 0.5em;
        }
        .comments:not(:empty) {
          margin-top: 1em;
          margin-left: 2em;
        }
        .head {
          display: inline-flex;
        }
        .published {
          font-size: 0.8em;
          color: ${css.infoColor};
          margin-left: 1em;
        }
        .published::before {
          /*content: "•";*/
          margin-left: 1em;
          padding-right: 1em;
        }
        .replies {
          font-size: 14px;
        }
        .replies:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        .table {
          display: table;
        }
        .table-row {
          display: table-row;
        }
        .table-cell {
          display: table-cell;
          vertical-align: middle;
        }
        .vote {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
        }
        .vote:hover {
          cursor: pointer;
        }
        .score-positive {
          font-size: 12px;
          color: green;
          font-weight: 600;
        }
        .score-negative {
          font-size: 12px;
          color: red;
          font-weight: 600;
        }
      `}</style>
    </article>
  )
}

export default CommentBranch
