import { FunctionComponent, useState, useCallback } from "react"
import CommentTree from "./tree"
import CommentInput from "./input"
import { Comment } from "../../types"
import React from "react"

type Props = {
  comments: Comment[]
  onLike: (commentId: string, amount: number) => void
  onDislike: (commentId: string, amount: number) => void
  onComment: (amount: number, text: string) => void
  onReply: (commentId: string, amount: number, text: string) => void
  selected?: string
}

const CommentSection: FunctionComponent<Props> = ({
  comments,
  onLike,
  onDislike,
  onComment,
  onReply,
  selected: initialSelected = null,
}) => {
  const [active, setActive] = useState(initialSelected)

  const commentComment = useCallback(
    (text: string) => {
      if (active) {
        onComment(1, text)
      }
    },
    [active]
  )

  const activate = useCallback((active: string | null) => {
    setActive(active)
  }, [])

  return (
    <div>
      <CommentTree
        comments={comments}
        onLike={onLike}
        onDislike={onDislike}
        onReply={activate}
        onSubmit={commentComment}
        active={active}
      >
        <CommentInput
          onSubmit={onComment}
          borderless
          autofocus={!active}
          placeholder="Write a new comment"
        />
      </CommentTree>

      <style jsx>{`
        div {
          padding: 0.5em;
        }
      `}</style>
    </div>
  )
}

export default CommentSection
