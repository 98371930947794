import { FunctionComponent } from "react"
//import { LinkProps } from "next/link"
import Link from "../../link"
import { LinkProps } from "@reach/router"
import Dropdown, { Props as DropdownProps } from "."
import DropdownItem, { Props as DropdownItemProps } from "./item"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useStyle } from "../../../hooks/use-style"
import React from "react"

export type Props = DropdownProps & {
  items: {
    [key: string]: DropdownItemProps & {
      to?: string | LinkProps<boolean> | (() => void)
    }
  }
}

const DropdownList: FunctionComponent<Props> = ({
  items,
  ...dropdownProps
}) => {
  const css = useStyle(theme => ({
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  return (
    <Dropdown {...dropdownProps}>
      <ul>
        {Object.entries(items).map(([key, item]) => (
          <li key={key}>
            {typeof item.to === "function" ? (
              <div className="clickable" onClick={item.to}>
                <DropdownItem {...item} />
              </div>
            ) : item.to ? (
              <Link
                {...(typeof item.to === "object"
                  ? { to: "/", ref: item.to }
                  : { to: item.to })}
              >
                <DropdownItem {...item} />
              </Link>
            ) : (
              <DropdownItem {...item} />
            )}
          </li>
        ))}

        <style jsx>{`
          ul {
            margin: 0;
            padding: 0;
            font-size: 14px;
            /*color: #747474;*/
            font-family: "Roboto", sans-serif;
          }
          li {
            display: flex;
            /*align-items: center;*/
          }
          li:hover {
            background-color: ${css.borderColor};
          }
          /*
          li:not(:first-child) {
            border-top: 1px solid ${css.borderColor};
            transition: 200ms border-color;
          }*/
          a,
          button {
            padding: 0;
            border-radius: 0.3em;
          }
          a {
            text-decoration: none;
            color: inherit;
          }
          /*
          button {
            font-weight: inherit;
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: inherit;
          }*/
          .clickable:hover {
            cursor:pointer;
          }
        `}</style>
      </ul>
    </Dropdown>
  )
}

export default DropdownList
